<template>
  <div>
    <b-row class="d-flex">
      <b-col cols="6">
        <feather-icon icon="PackageIcon" size="19"/>
        <h4 class="d-inline-block mb-0 ml-50">PRICE LABS</h4>
      </b-col>
      <b-col cols="6" class="text-right">
        <b-img style="max-height: 100%; max-width: 150px" v-bind:src="priceLabsLogo"/>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group label="name" label-for="min">
          <b-form-input readonly v-model="priceLabs.name"/>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="pms" label-for="base">
          <b-form-input readonly v-model="priceLabs.pms"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Min" label-for="min">
          <b-form-input id="min" v-model="priceRequest.min"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Base" label-for="base">
          <template #description>
            <span class="font-weight-bolder my-3">{{msg('Recommended Base Price')}}: </span>{{ priceLabs.recommendedBasePrice }}
          </template>
          <b-form-input id="base" v-model="priceRequest.base"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group label="Max" label-for="max">
          <b-form-input id="max" v-model="priceRequest.max"/>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-button block v-bind:disabled="updating"  variant="primary" class="mt-2" @click="tryUpdatePriceLabs">{{msg('Update')}}</b-button>
      </b-col>
    </b-row>

    <h4 class="my-2 ">{{msg('Occupancy')}}</h4>
    <b-list-group class="mb-1" horizontal="md">
      <b-list-group-item class="col-3" v-for="option in occupancy.base" v-bind:key="option.key">
        <p class="m-0 d-inline-block">{{option.label}}</p>
        <b-badge style="float: right" v-bind:variant="getPricesVariant(priceLabs.occupancy[option.statusKey])"  pill>{{priceLabs.occupancy[option.key]}}</b-badge>
      </b-list-group-item>
    </b-list-group>
    <b-list-group horizontal="md">
      <b-list-group-item class="col-3" v-for="option in occupancy.market" v-bind:key="option.key">
        <p class="m-0 d-inline-block">{{option.label}}</p>
        <b-badge style="float: right" v-bind:variant="getPricesVariant(priceLabs.occupancy[option.statusKey])" pill>{{priceLabs.occupancy[option.key]}}</b-badge>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
import {mapActions} from "vuex";

export default {
  name: "RealEstatePriceLabs",
  props: {
    priceLabs: {
      type: Object,
      required: true
    }
  },
  data:()=>({
    updating: false,
    priceRequest: {
      min: 0,
      base: 0,
      max: 0
    }
  }),
  watch: {
    priceLabs: {
      handler(){
        this.priceRequest = {
          min: this.priceLabs?.min,
          base: this.priceLabs?.base,
          max: this.priceLabs?.max
        }
      },
      immediate: true,
    }
  },
  computed:{
    occupancy() {
      let $this = this
      return {
        base: [
          {key: 'next7Days', statusKey: "next7DaysStatus", label: $this.$t('nextDays',{days:'7'})},
          {key: 'next30Days', statusKey: "next30DaysStatus", label: $this.$t('nextDays',{days:'30'})},
          {key: 'next60Days', statusKey: "next60DaysStatus", label: $this.$t('nextDays',{days:'60'})},
          {key: 'next90Days',statusKey: "next90DaysStatus", label: $this.$t('nextDays',{days:'90'})}
        ],
        market: [
          {key: 'marketNext7Days',  statusKey: "next7DaysStatus", label: $this.$t('marketNextDays',{days:'7'})},
          {key: 'marketNext30Days', statusKey: "next30DaysStatus", label: $this.$t('marketNextDays',{days:'30'})},
          {key: 'marketNext60Days', statusKey: "next60DaysStatus", label: $this.$t('marketNextDays',{days:'60'})},
          {key: 'marketNext90Days', statusKey: "next90DaysStatus", label: $this.$t('marketNextDays',{days:'90'})}
        ]
      }
    },
    priceLabsLogo(){
      return require("@/assets/images/platforms/priceLabs.png")
    }
  },
  methods:{
  ...mapActions('realEstatePriceLabs', ['updatePriceLabs']),
    async tryUpdatePriceLabs(){
      this.updating = true
      try{
        let result = await this.updatePriceLabs({...this.priceRequest, id: this.priceLabs.id, pms: this.priceLabs.pms})
        // console.log("tryUpdatePriceLabs", result)
        this.$emit('onUpdate',result)
      }finally {
        this.updating = false
      }
    },
    getPricesVariant(nextDaysStatus){
      if(nextDaysStatus === 'ABOVE_MARKET'){
        return "success";
      }
      if(nextDaysStatus === 'UNDER_MARKET'){
        return "danger";
      }
      return "primary"
    }
  }
}
</script>



<style scoped>

</style>
