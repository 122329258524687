export const occupancyStatus = {
    UNDER_MARKET: "UNDER_MARKET", WITH_MARKET: "WITH_MARKET", ABOVE_MARKET : "ABOVE_MARKET"
}

export function PriceLabs(other) {
    other = other || {};
    return {
        ...other,
        id: other.id,
        name: other.name,
        pms: other.pms,
        min: other.min,
        base: other.base,
        max: other.max,
        group: other.group,
        subgroup: other.subgroup,
        tags: other.tags,
        notes: other.notes,
        recommendedBasePrice: other.recommendedBasePrice,
        occupancy: PriceLabsOccupancy(other.occupancy),
        isHidden: other.isHidden,
        pushEnabled: other.pushEnabled,
        lastDatePushed: other.lastDatePushed,
        lastRefreshedAt: other.lastRefreshedAt,
    }
}

export function PriceLabsOccupancy(other) {
    other = other || {}
    let occupancy = {
        next7Days: other.next7Days,
        marketNext7Days: other.marketNext7Days,
        next30Days: other.next30Days,
        marketNext30Days: other.marketNext30Days,
        next60Days: other.next60Days,
        marketNext60Days: other.marketNext60Days,
        next90Days: other.next90Days,
        marketNext90Days: other.marketNext90Days,
    }
    return compareMarketStatus(occupancy);
}

function compareMarketStatus(occupancy) {
    if (occupancy == null) {
        return occupancy;
    }
    for (const o of occupancyKeys) {
        let base = occupancy[o.base];
        let market = occupancy[o.market];
        occupancy[o.statusKey] = occupancyStatus.WITH_MARKET;
        if (base == null || market == null) {
            continue;
        } if(base === market){
            continue;
        }
        if (!base.includes("%") || !market.includes("%")) {
            console.log("occupancy doesnt contains %", {occupancy, base, market})
            continue;
        }
        let baseNumber = parseInt((base.substring(0, base.indexOf("%"))).trim())
        let marketNumber = parseInt((market.substring(0, market.indexOf("%"))).trim());
        occupancy[o.statusKey] = baseNumber > marketNumber ? occupancyStatus.ABOVE_MARKET : occupancyStatus.UNDER_MARKET;
    }
    return occupancy;
}

export const occupancyKeys = [
    {base: 'next7Days', market: 'marketNext7Days', statusKey: "next7DaysStatus"},
    {base: 'next30Days', market: 'marketNext30Days', statusKey: "next30DaysStatus"},
    {base: 'next60Days', market: 'marketNext60Days', statusKey: "next60DaysStatus"},
    {base: 'next90Days', market: 'marketNext90Days', statusKey: "next90DaysStatus"}
]
